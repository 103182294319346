import styled from '@emotion/styled'
import { ChevronsDown, ChevronsUp, Pin, Rumble } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import { Link } from 'react-scroll'

export interface Props {
  description?: string
  isExpanded?: boolean
  languageCode: string
  onClick?: () => void
  place?: string
  title: string
}

export const Work = memo(function Work({
  description,
  languageCode,
  onClick,
  place,
  title,
}: Props) {
  if (!title) {
    return null
  }

  const ctaLabel = useVocabularyData('send-your-application', languageCode)

  return (
    <Container activeClassName="expanded">
      <Head>
        <Button>
          <Rumble />
          <Wrapper>
            <Title>{title}</Title>
            {place ? (
              <Place dial={4} row>
                <Pin />
                {place}
              </Place>
            ) : undefined}
          </Wrapper>
          <SVG>
            <State>
              {({ expanded }) => (expanded ? <ChevronsUp /> : <ChevronsDown />)}
            </State>
          </SVG>
        </Button>
      </Head>
      {description ? (
        <Panel>
          <State>
            {({ expanded }) => (
              <AnimatePresence>
                {expanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Text>
                      <Description
                        dangerouslySetInnerHTML={{ __html: description }}
                      />

                      <CTA
                        offset={-100}
                        onClick={onClick}
                        spy={true}
                        smooth={true}
                        to="application-form"
                      >
                        {ctaLabel}
                      </CTA>
                    </Text>
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </State>
        </Panel>
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-top: 0.375rem;
`

const Head = styled(AccordionItemHeading)`
  cursor: pointer;
`

const Button = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  padding: 1rem 2.125rem 0.875rem 3.25rem;
  position: relative;

  > svg {
    width: 1rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    position: absolute;
    top: 1.4375rem;
    left: 1.375rem;
  }
`

const Wrapper = styled.div``

const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    line-height: 1.25rem;
  }
`

const Place = styled(FlexBox)`
  font-size: 0.875rem;
  line-height: 1.625rem;

  svg {
    width: auto;
    height: 0.875rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark4};
    margin-right: 0.375rem;
  }
`

const SVG = styled.div`
  margin-left: auto;

  svg {
    width: auto;
    height: 1rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.secondaryLight};
    stroke-width: 2;
  }
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  display: block !important;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  overflow: hidden;
  padding: 0 5.5rem 0 3.3125rem;

  @media (max-width: 574px) {
    padding: 0 2.125rem 0 1.375rem;
  }
`

const Text = styled.div`
  padding: 2.75rem 0 5.1875rem;

  @media (max-width: 1023px) {
    padding-bottom: 3.3125rem;
  }
  @media (max-width: 574px) {
    padding-bottom: 2.5rem;
  }
`

const Description = styled.div`
  p {
    margin-block-end: 1em;
  }

  ul {
    margin: 1.875rem 0;
    padding: 0;
    li {
      background: url('/rumble.svg') no-repeat left 0.5rem;
      border-bottom: 0.0625rem solid
        ${({ theme }) => theme.colors.variants.neutralLight1};
      margin-top: 1rem;
      padding: 0 0 1rem 1.5rem;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`

const CTA = styled(Link)`
  display: inline-flex;
  align-items: center;
  height: 3.75rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.secondaryLight};
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.0406rem;
  line-height: 1.25rem;
  margin-top: 3.75rem;
  padding: 0 2rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.secondaryLight};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`
